// @ts-nocheck
import { CommunityWallet, TokenPermissionedChat } from '@collabland/sdk';
import {
  FETCHED_AXIE_RULES,
  getFlowNftContracts,
  getNetworkNameByTokenType,
  getSchemeByTokenType,
  getStakingContracts,
  OTTERSPACE_BADGE_CONTRACT_ADDRESSES,
  setAxieRules,
  setFlowNftContracts,
  setRoninTokens,
  setStakingContracts,
  tokenTypesWithMetadata,
  tokenTypesWithTokenId,
} from 'constants/community';
import {
  DiscordToken,
  RoleCompositionFormData,
  RuleVersions,
} from 'custom-types';
import httpClient, { getCollabClient } from 'sdk';
import {
  expiredTokenRE,
  stripEmptyStringAttributes,
  tokenIdIsUrl,
} from 'utils';
import { AppDispatch, RootState } from '../index';
import { addToast } from '../toasts/actionCreators';
import { TOKEN_EXPIRED } from '../user/actionTypes';
import * as actionTypes from './actionTypes';

type ExtraKeys =
  | 'traitsId'
  | 'contractAddress'
  | 'filter'
  | 'type'
  | 'collectionName'
  | 'currency'
  | 'taxon'
  | 'chainId';

// const METADATA_COUNT_QUERY = `$count($[$lowercase(asset) = $lowercase($_asset)].tokens.metadata) = 0 ? false :
// ($gte($[$lowercase(asset) = $lowercase($_asset)].tokens.metadata{$toString(%.id): attributes} ~>
// $each(function($v, $k) {
//   $includes(
//     $map($v, function($t) {
//       {
//         'trait_type': $lowercase($trim($t.trait_type)),
//         'value': $lowercase($trim($t.value))
//       }
//     }),
//     $_traits) ? 1 : 0}) ~> $sum, $_minCount))`;
// const TOKEN_BALANCE_QUERY = `$[asset = $_asset and $between(balance, $_minCount, $_maxCount)]`;

export function fetchRequest() {
  return {
    type: actionTypes.FETCH_REQUEST,
  } as const;
}

export function fetchError(error: string) {
  return {
    type: actionTypes.FETCH_ERROR,
    payload: error,
  };
}

export function clearError() {
  return {
    type: actionTypes.CLEAR_ERROR,
  };
}

function parseRoleCompositionTpcs(tpcs: TokenPermissionedChat[]) {
  const rules = tpcs
    .filter((tpc) => tpc.scheme === 'role_composition')
    .map((rule) => {
      let newrule = rule;
      newrule = {
        ...rule,
        variables: !rule.variables.roleComposition
          ? {
              roleComposition: {
                action: rule.variables.action,
                condition: rule.variables.condition,
                operator: rule.variables.operator,
              },
            }
          : rule.variables,
      };
      if (newrule.variables.roleComposition.action === 'removeOrAdd') {
        newrule.variables.roleComposition.action = 'remove';
        newrule.variables.roleComposition.enforceAction = true;
      } else if (newrule.variables.roleComposition.action === 'addOrRemove') {
        newrule.variables.roleComposition.action = 'add';
        newrule.variables.roleComposition.enforceAction = true;
      } else newrule.variables.roleComposition.enforceAction = false;
      return newrule;
    });
  return rules;
}

function parseTpcs(tpcs: TokenPermissionedChat[]) {
  return tpcs
    .filter((tpc) => tpc.scheme !== 'VC' && tpc.scheme !== 'role_composition')
    .map((tpc) => {
      if (
        tpc.variables &&
        (tpc.variables._asset || tpc.variables?._filter?.asset)
      ) {
        const isV2_1 =
          tpc.version === RuleVersions.V2_1 || !!tpc.variables?._filter?.asset;
        const asset = tpc.variables?._filter?.asset ?? tpc.variables._asset;
        const [parsedChain, parsedAsset, parsedAddress] = asset.split('/');
        const [parsedNetwork, parsedChainId] = parsedChain.split(':');

        let isSolana = false;

        // If the parsed network is any of the cases below
        // assign their respective chainIds otherwise
        // read the chainId from the parsed asset string
        let chainId = '';
        switch (parsedNetwork) {
          case 'solana':
            chainId = '8000000000101';
            isSolana = true;
            break;
          case 'tezos':
            chainId = '8000000000401';
            break;
          case 'flow':
            chainId = '8000000000001';
            break;
          case 'polkadot':
            chainId = '8000000000501';
            break;
          case 'immutable_x':
            chainId = '8000000000601';
            break;
          case 'LOOPRING':
            chainId = '8000000000701';
            break;
          case 'xrpl':
            chainId =
              parsedChainId === 'nft_devnet'
                ? '8000000000803'
                : '8000000000801';
            break;
          case 'near':
            chainId = '8000000000301';
            break;
          case 'bitcoin':
            chainId = '8000000000901';
            break;
          case 'axie':
            chainId = '8000000002020';
            isSolana = true;
            break;
          case 'gitcoin':
            chainId = '8000000001001';
            break;

          default:
            chainId = parsedChainId;
        }

        const [initialType, initialAddress] = parsedAsset.split(':');

        // Token type correction for OpenSea tokens
        // Most of the application expects OPEN_SEA
        // but v2 rules come with "OPENSEA" as value
        let type = initialType === 'OPENSEA' ? 'OPEN_SEA' : initialType;

        // Token type correction for BEP tokens
        // We're saving them as ERC tokens but need to convert
        // them to BEP to display them correctly
        if (chainId === '56') type = type.replace('ERC', 'BEP');

        // Parsing Solana token intricacies
        if (isSolana) {
          // eslint-disable-next-line no-param-reassign
          tpc.type = `SOLANA_${initialType}`;

          type = tpc.type;
        }

        // Parsing Tezos token intricacies
        if (chainId === '8000000000401') {
          // eslint-disable-next-line no-param-reassign
          tpc.type = `TEZOS_${initialType}`;

          type = tpc.type;
        }

        let contractAddress =
          isSolana && initialType === 'NFT' ? parsedAddress : initialAddress;

        // Parsing Flow contracts
        if (chainId === '8000000000001') {
          // eslint-disable-next-line no-param-reassign
          tpc.type = `FLOW_${initialType}`;
          type = tpc.type;
          // Only looking for the contractName from A.<contractAddress>.<contractName>
          const [, , flowContract] = contractAddress.split('.');
          // eslint-disable-next-line no-param-reassign
          tpc.collectionName = flowContract;
        }

        // Parsing XRPL contracts
        if (chainId === '8000000000801' || chainId === '8000000000803') {
          // eslint-disable-next-line no-param-reassign
          tpc.type = `XRPL_${initialType}`;
          type = tpc.type;
          // eslint-disable-next-line
          contractAddress = tpc.contractAddress.split('.')[0];
          // eslint-disable-next-line
          tpc['taxon'] = tpc.contractAddress.split('.')[1];
        }

        if (chainId === '8000000000901') {
          // eslint-disable-next-line no-param-reassign
          tpc.type = `Bitcoin_${initialType}`;
          type = tpc.type;
          // eslint-disable-next-line
          contractAddress = tpc.contractAddress;
        }

        //Axie rules
        if (chainId === '8000000002020') {
          // eslint-disable-next-line no-param-reassign
          tpc.type = `AXIE`;
          type = tpc.type;
        }

        const filter = isSolana ? initialAddress : '';

        let raftTokenId, otterspaceName;
        if (
          type === 'ERC721' &&
          OTTERSPACE_BADGE_CONTRACT_ADDRESSES.includes(contractAddress)
        ) {
          type = 'otterspace';
          const traits = tpc.variables?._filter?.filters?.[0]?.traits;
          raftTokenId =
            traits.find((t) => t.trait_type === 'raftTokenId')?.value ?? '';
          otterspaceName =
            traits.find((t) => t.trait_type === 'name')?.value ?? '';

          // eslint-disable-next-line no-param-reassign
          tpc.variables._filter.filters[0].traits = [];
        }
        let traits = (
          (isV2_1
            ? tpc.variables?._filter?.filters?.[0]?.traits
            : tpc.variables._traits) || []
        ).map((trait) => ({
          name: trait.trait_type,
          value: trait.value,
        }));

        const traitsCondition =
          (isV2_1 ? tpc.variables?._filter?.filters?.[0]?.condition : 'and') ||
          'and';

        const traitsId =
          isV2_1 && !isSolana
            ? tpc.tokenId && decodeURIComponent(tpc.tokenId)
            : tpc.variables._tokenId;

        const minCount = isV2_1
          ? tpc.variables?._filter?.filters?.[0]?.minCount
          : tpc.variables._minCount;

        const maxCount = isV2_1
          ? tpc.variables?._filter?.filters?.[0]?.maxCount
          : tpc.variables._maxCount;

        if (type === 'staking') {
          const contract = getStakingContracts().find((f) => {
            const cName = f.contractName.includes('-')
              ? f.contractName.split('-')[1].trim()
              : f.contractName;
            return cName === tpc.variables?.stakingAssetName;
          });
          // eslint-disable-next-line no-param-reassign
          tpc.collectionName = contract.contractName;
        }

        if (chainId === 'twitter') {
          type = 'Twitter';
          contractAddress = '';
        }

        if (chainId !== 'twitter') {
          chainId = parseInt(chainId, 10);
        }

        return {
          ...tpc,
          minToken: minCount ?? tpc.minToken,
          maxToken: maxCount ?? tpc.maxToken,
          streamReceiver: tpc.variables?._to,
          minFlowRate: tpc.variables?._minMonthlyRate,
          chainId,
          type,
          filter,
          contractAddress,
          traits,
          traitsId,
          traitsCondition,
          raftTokenId,
          otterspaceName,
        };
      }

      const extraFields: {
        [key in ExtraKeys]?: string | number;
      } = {};

      if (!tpc.type?.startsWith('SOLANA')) {
        extraFields.traitsId = tpc.tokenId;
      }

      if (tpc.type === 'POAP') {
        extraFields.contractAddress = tpc.eventId;
      }

      if (tpc.type === 'FLOW_FT') {
        // Splitting the path from the rest of the address A.<contractAddress>.<contractName>.<publicPath>
        const lastDot = tpc.contractAddress.lastIndexOf('.');
        const flowAddress = tpc.contractAddress.substring(0, lastDot - 1);
        const flowPath = tpc.contractAddress.substring(lastDot + 1);
        extraFields.contractAddress = flowAddress;
        extraFields.collectionName = flowPath;
      }

      if (tpc.type === 'FLOW_NFT') {
        // Splitting the contract name from the rest of the address A.<contractAddress>.<contractName>.<publicPath>
        const [, , contractName] = tpc.contractAddress.split('.');
        extraFields.collectionName = contractName;
      }

      if (tpc.type === 'XRPL_NFT') {
        // eslint-disable-next-line
        extraFields.contractAddress = tpc.contractAddress.split('.')[0];
        // eslint-disable-next-line
        extraFields.taxon = tpc.contractAddress.split('.')[1];
      }

      if (tpc.type === 'XRPL_FT') {
        // eslint-disable-next-line
        extraFields.contractAddress = tpc.contractAddress.split('.')[1];
        // eslint-disable-next-line
        extraFields.currency = tpc.contractAddress.split('.')[0];
      }

      // Parsing SOLANA tokens coming from the bot
      if (tpc.type === 'SOLANA_NFT' && !tpc.variables) {
        extraFields.contractAddress = tpc.tokenId;
        extraFields.filter = tpc.contractAddress;
      }

      if (tpc.type === 'staking' && !tpc.variables) {
        const contract = getStakingContracts().find((f) => {
          const cName = f.contractName.includes('-')
            ? f.contractName.split('-')[1].trim()
            : f.contractName;
          return cName === tpc.collectionName;
        });

        extraFields.collectionName =
          contract?.contractName ?? tpc.collectionName;
      }

      if (tpc.scheme === 'gnosis') {
        const [parsedChain, parsedAsset] = tpc.asset?.split('/');
        const [, parsedChainId] = parsedChain.split(':');

        const [parsedType, parsedAddress] = parsedAsset.split(':');

        extraFields.chainId = +parsedChainId;
        extraFields.type = parsedType;
        extraFields.contractAddress = parsedAddress;
      }

      if (tpc.type === 'friendtech') {
        extraFields.chainId = 8000000002001;
        extraFields.minAmount = tpc.minToken;
        extraFields.maxAmount = tpc.maxToken;
        extraFields.address = tpc.contractAddress;
        extraFields.type = 'FRIEND_TECH';
      }

      // Check if token id is either ipfs http or https url
      if (tpc.tokenId)
        if (tokenIdIsUrl(tpc.tokenId))
          extraFields.traitsId = decodeURIComponent(tpc.tokenId);

      // Converting ERC to BEP for simple rules
      if (tpc.chainId === 56)
        extraFields.type = tpc.type?.replace('ERC', 'BEP');

      const chainId =
        tpc.type !== 'IMMUTABLE_X' && !tpc.variables
          ? tpc.chainId
          : '8000000000601';

      return {
        traits: [],
        traitId: '',
        ...tpc,
        // @ts-ignore
        chainId: parseInt(chainId, 10),
        ...extraFields,
      };
    });
}

export function getCommunitiesAction() {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<void> => {
    dispatch(fetchRequest());
    try {
      // Non-blocking call to fetch the flow NFT contracts
      if (getCollabClient().flowContracts)
        getCollabClient()
          .flowContracts.getContracts()
          .then((flowContracts) => setFlowNftContracts(flowContracts.nfts))
          .catch((e) => console.error(e)); // eslint-disable-line no-console

      if (getCollabClient().stakingContracts)
        getCollabClient()
          .stakingContracts.getStakingContracts()
          .then((stakingContracts) => {
            const flatContract = stakingContracts.flatMap((contract) => {
              const { supportedAssets } = contract;
              if (supportedAssets.length > 1)
                return supportedAssets.map((asset) => ({
                  ...contract,
                  contractName: `${contract.contractName} - ${asset.name}`,
                  stakingAssetName: asset.name,
                }));
              return contract;
            });
            setStakingContracts(flatContract);
          })
          .catch((e) => console.error(e)); // eslint-disable-line no-console

      if (getCollabClient().evmNetworkConfig)
        getCollabClient()
          .evmNetworkConfig.getEvmTokens('ronin')
          .then((json) => {
            const roninToken = [
              {
                label: `WildForestLord - Staked`,
                value: '0xa1ce53b661be73bf9a5edd3f0087484f0e3e7363',
                tokenType: 'WILD_FOREST',
                tokenSymbol: 'LAND',
                tokenName: 'Staked',
              },
            ].concat(
              json.map((token) => ({
                label: `${token.tokenSymbol} - ${token.tokenName}`,
                value: token.address,
                tokenType: token.tokenType,
                tokenSymbol: token.tokenSymbol,
                tokenName: token.tokenName,
              })),
            );
            setRoninTokens(roninToken);
          });

      const axieRules = FETCHED_AXIE_RULES; //TODO: replace with SDK getCollabClient().axieRules.getAxieRuleList();
      setAxieRules(axieRules);

      const data =
        await getCollabClient().account.getAdministratedCommunities();
      const userData = getState().user;

      let guildsData = [];
      guildsData = data.items
        .filter((item) => ['active', 'paused'].includes(item.status))
        .map((item) => {
          const parsedRoleCompositionTpcs = parseRoleCompositionTpcs(
            item.tpcs ?? [],
          );
          return {
            ...item,
            communityId: item.communityId.toString(),
            /* let's find a better way to do this in the future, since collabland-admin is only the default 
            name of a new server's admin but someone can rename it to something else through discord. */
            roles:
              userData.platform === 'discord'
                ? item.roles.filter((role) => role.name !== 'collabland-admin')
                : [],
            tpcs: parseTpcs(item.tpcs ?? []),
            roleCompositionTpcs: parsedRoleCompositionTpcs,
          };
        });

      // Add an invalidTpcs array which contains all the TPCs
      // that belong to a role that doesn't exist in the community
      // roles array, it will be mostly an empty array
      guildsData = guildsData.map((community) => {
        const communityRoles = community.roles.map((role) => role.id);
        const invalidTpcs = community.tpcs.filter(
          (tpc) => !communityRoles.includes(tpc.roleId),
        );
        return { ...community, invalidTpcs };
      });

      dispatch({
        type: actionTypes.GET_COMMUNITY_LIST,
        payload: {
          items: guildsData,
        },
      });
    } catch (e) {
      if (e.status === 401) dispatch({ type: TOKEN_EXPIRED });
      // eslint-disable-next-line no-console
      console.error('ERROR PARSING COMMUNITIES', e);
    }
  };
}

export function refreshTpcs(communityPk: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const result = await getCollabClient().community.findTpcs(communityPk);
      const parsedTpcs = parseTpcs(result.items);
      const parsedRoleCompositionTpcs = parseRoleCompositionTpcs(
        result.items ?? [],
      );

      dispatch({
        type: actionTypes.UPDATE_COMMUNITY_TPCS,
        payload: {
          communityPk,
          tpcs: parsedTpcs,
          roleCompositionTpcs: parsedRoleCompositionTpcs,
        },
      });
    } catch (e) {
      if (e.status === 401) dispatch({ type: TOKEN_EXPIRED });
    }
  };
}

interface RoleData {
  roleId: string;
  page?: 'tgrs' | 'role-composition';
}

export function addSelectedRole({ roleId, page = 'tgrs' }: RoleData) {
  return {
    type: actionTypes.ADD_SELECTED_ROLE,
    payload: { roleId, page },
  };
}

export function removeSelectedRole({ roleId, page = 'tgrs' }: RoleData) {
  return {
    type: actionTypes.REMOVE_SELECTED_ROLE,
    payload: { roleId, page },
  };
}

export const uploadImageToAWS = async (file: File | null): Promise<string> => {
  const formData = new FormData();
  if (!file) return '';
  formData.append('file', file);
  const token = localStorage.getItem('collabLandToken');
  const res = await fetch(`${process.env.REACT_APP_API_URL}/files`, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${token}`,
      'x-api-key': process.env.REACT_APP_COLLABLAND_KEY || '',
    },
  });

  const body = await res.json();
  return body.files[0].key;
};

type CommunityConfig = {
  welcomeMessage?: string;
  isWelcomeMsgEnabled?: boolean;
  disableBkgBalCheck?: boolean;
  disableEVMListener?: boolean;
  disableUpdates?: boolean;
  disableUpdatesOnJoinChannel?: boolean;
};

export function updateCommunityConfig(
  communityId: string,
  data: CommunityConfig,
) {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<void> => {
    dispatch(fetchRequest());
    try {
      const { items } = getState().community.communityGroups;
      const community = items.find(
        (item) => item.communityId === communityId,
      ) || { pk: communityId, tpcs: [], communityId: '' };

      await getCollabClient().community.updateById(community.pk, data);

      /* dispatch({
      type: actionTypes.UPDATE_DISCORD_COMMUNITY,
      payload: { communityId, message, media, tpcs },
    }); */

      dispatch(getCommunitiesAction());
    } catch (e) {
      if (e.status === 401) dispatch({ type: TOKEN_EXPIRED });
      else dispatch(fetchError(e.message));
    }
  };
}

export function removeCommunityTPCsByRole(
  communityId: string,
  roleId?: string,
) {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<void> => {
    dispatch(fetchRequest());
    try {
      const communityObj = getState().community;
      const { items } = communityObj.communityGroups;
      const community = items.find(
        (item) => item.communityId === communityId,
      ) || { pk: communityId, roles: [], tpcs: [], communityId: '' };

      let tpcs = roleId
        ? community.tpcs.filter((tpc) => tpc.roleId === roleId)
        : community.tpcs;

      if (roleId)
        dispatch({
          type: actionTypes.FILTER_TPC_BY_ROLE,
          payload: { communityId, roleId },
        });
      // Delete flow
      await Promise.all(
        tpcs.map((tpc) =>
          getCollabClient().community.deleteTpc(community.pk, tpc.sk),
        ),
      );
      dispatch(refreshTpcs(community.pk));
    } catch (e) {
      if (e.status === 401) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(e.message));
      dispatch(
        addToast({
          status: 'error',
          description: 'Failed to delete TGR',
        }),
      );
    }
  };
}

export function removeCommunityRulesByRole(
  communityId: string,
  roleId: string,
) {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<void> => {
    dispatch(fetchRequest());
    try {
      const communityObj = getState().community;
      const { items } = communityObj.communityGroups;
      const community = items.find(
        (item) => item.communityId === communityId,
      ) || {
        pk: communityId,
        roles: [],
        tpcs: [],
        roleCompositionTpcs: [],
        communityId: '',
      };

      dispatch({
        type: actionTypes.FILTER_TPC_BY_ROLE,
        payload: { communityId, roleId },
      });
      // Delete flow
      await Promise.all(
        community.roleCompositionTpcs
          .filter((tpc) => tpc.roleId === roleId)
          .map((tpc) =>
            getCollabClient().community.deleteTpc(community.pk, tpc.sk),
          ),
      );
      dispatch(refreshTpcs(community.pk));
    } catch (e) {
      if (e.status === 401) dispatch({ type: TOKEN_EXPIRED });
      else dispatch(fetchError(e.message));
    }
  };
}

function parseApiError(e: any) {
  let errorMsg = '';
  if (e.status === 422)
    errorMsg = e.response?.body?.error?.details
      ?.map((error) => error?.message)
      .join('\n');
  return errorMsg ?? (e as Error).message;
}

function handleStatusErrors(
  error,
  { forwardApiError }: { forwardApiError?: boolean } = {
    forwardApiError: true,
  },
) {
  let errorMessage = error?.response?.obj?.error?.message as string;
  if (error.status === 401) {
    if (expiredTokenRE.test(errorMessage)) {
      return { isTokenExpired: true, errorMessage };
    }
  } else if (error.status === 422) {
    errorMessage = parseApiError(error);
  } else if (error.status === 404) {
    return { errorMessage };
  } else if (error.status === 400) {
    return { errorMessage: error?.response?.body?.error?.message };
  } else {
    // A very generic catch-all
    errorMessage =
      forwardApiError && errorMessage
        ? errorMessage
        : "Something went wrong, check your bot's configuration.";
  }
  return { isTokenExpired: false, errorMessage };
}

export function removeCommunityTPCsById(
  communityPK: string,
  sk: string,
  callback?: () => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchRequest());
    try {
      await getCollabClient().community.deleteTpc(communityPK, sk);

      await dispatch(refreshTpcs(communityPK));

      await dispatch(
        addToast({
          status: 'success',
          description: 'TGR was deleted successfully',
        }),
      );

      callback();
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      await dispatch(
        addToast({
          status: 'error',
          description: errorMessage,
        }),
      );
      callback();
    }
  };
}

function dataByTokenType(tpc: DiscordToken) {
  let contractAddress = '',
    collectionName = '',
    eventId = '';

  switch (tpc.tokenType) {
    case 'POAP':
      contractAddress = tpc.tokenType;
      eventId = tpc.address?.trim();
      break;
    case 'OPEN_SEA':
      contractAddress = tpc.address?.trim();
      collectionName = tpc.collectionName?.trim();
      break;
    case 'FLOW_FT':
      contractAddress = `${tpc.address?.trim()}..${tpc.collectionName?.trim()}`;
      break;
    case 'FLOW_NFT':
      const contract = getFlowNftContracts().find(
        (c) => c.name === tpc.collectionName,
      );
      if (!contract) {
        contractAddress = `A.${tpc.address?.trim()}.${tpc.collectionName?.trim()}`;
      } else {
        contractAddress = `A.${contract.address}.${contract.name}.${contract.collection}.${contract.path}`;
      }
      break;
    case 'XRPL_FT':
      if (tpc.address && tpc.currency)
        contractAddress = `${tpc.currency?.trim()}.${tpc.address?.trim()}`;
      else contractAddress = tpc.currency?.trim();
      break;
    case 'XRPL_NFT':
      if (tpc.address && tpc.taxon)
        contractAddress = `${tpc.address?.trim()}.${tpc.taxon?.trim()}`;
      else contractAddress = tpc.address?.trim();
      break;
    case 'Bitcoin_Stamps':
      contractAddress = tpc.address;
      break;
    case 'Bitcoin_Ordinals':
      collectionName = tpc.collectionName?.trim();
      break;
    case 'staking':
      const cName = tpc.collectionName.includes('-')
        ? tpc.collectionName.split('-')[1].trim()
        : tpc.collectionName;

      const stakingContract = getStakingContracts().find((c) => {
        const contractName = c.contractName.includes('-')
          ? c.contractName.split('-')[1].trim()
          : c.contractName;
        return contractName === cName;
      });
      contractAddress = stakingContract.contractAddress;
      collectionName = tpc.traits.length > 0 ? '' : cName;
      break;
    case 'SOLANA_NFT':
      contractAddress = tpc?.filter ?? '';
      break;
    case 'ROLL':
    case 'gnosis':
    case 'otterspace':
    case 'Twitter':
      contractAddress = '';
      break;
    default:
      contractAddress = tpc.address?.trim();
      break;
  }

  return { contractAddress, collectionName, eventId };
}

function overrideTokenType(tpc: DiscordToken) {
  // Token type correction for OpenSea tokens
  // Legacy rules require "OPEN_SEA" value
  // v2 rules require "OPENSEA" value
  if (tpc.tokenType === 'OPEN_SEA' && tpc.traits.length > 0) return 'OPENSEA';
  // Token type correction for BEP tokens
  // CL runtime has some issues with the BEP
  // tokens so we're saving them as ERC
  if (tpc.tokenType.startsWith('BEP'))
    return tpc.tokenType.replace('BEP', 'ERC');
  if (tpc.tokenType.startsWith('SOLANA') && tpc.traits?.length > 0)
    return tpc.tokenType.replace('SOLANA_', '');
  if (tpc.tokenType.startsWith('TEZOS') && tpc.traits?.length > 0)
    return tpc.tokenType.replace('TEZOS_', '');
  if (tpc.tokenType.startsWith('FLOW') && tpc.traits?.length > 0)
    return tpc.tokenType.replace('FLOW_', '');
  if (tpc.tokenType.startsWith('XRPL') && tpc.traits?.length > 0)
    return tpc.tokenType.replace('XRPL_', '');
  if (tpc.tokenType === 'otterspace') return 'ERC721';
  return tpc.tokenType;
}

function overrideChainType(tpc: DiscordToken) {
  if (tpc.tokenType.startsWith('SOLANA')) return 'mainnet-beta';
  if (
    tpc.tokenType.startsWith('TEZOS') ||
    tpc.tokenType.startsWith('FLOW') ||
    tpc.tokenType?.startsWith('NEP')
  )
    return 'mainnet';
  if (tpc.tokenType.startsWith('XRPL'))
    return tpc.chainType === '8000000000803' ? 'nft_devnet' : 'mainnet';
  if (tpc.tokenType === 'RMRK') return 'kusama';
  if (tpc.tokenType === 'IMMUTABLE_X') return 'mainnet';
  return parseInt(tpc.chainType || '0', 10);
}

function overrideAddress(tpc: DiscordToken) {
  switch (tpc.tokenType) {
    case 'OPEN_SEA':
      return tpc.collectionName;
    case 'FLOW_FT':
      return `A.${tpc.address?.trim()}.${tpc.collectionName?.trim()}`;
    case 'FLOW_NFT':
      const contract = getFlowNftContracts().find(
        (c) => c.name === tpc.collectionName,
      );
      if (!contract)
        return `A.${tpc.address?.trim()}.${tpc.collectionName?.trim()}`;
      return `A.${contract.address}.${contract.name}.${contract.collection}.${contract.path}`;
    case 'XRPL_FT':
      if (tpc.address && tpc.currency)
        return `${tpc.currency?.trim()}.${tpc.address?.trim()}`;
      return tpc.currency?.trim();
    case 'XRPL_NFT':
      if (tpc.address && tpc.taxon)
        return `${tpc.address?.trim()}.${tpc.taxon?.trim()}`;
      return tpc.address?.trim();
    case 'Bitcoin_Stamps':
      return tpc.address;
    case 'Bitcoin_Ordinals':
      return tpc.collectionName;
    case 'staking':
      const stakingContract = getStakingContracts().find(
        (c) => c.contractName === tpc.collectionName,
      );
      if (!stakingContract) return `${tpc.address?.trim()}`;
      return `${stakingContract.contractAddress}`;
    case 'SOLANA_NFT':
      return `${tpc.filter}/${tpc.address?.trim()}`;
    case 'otterspace':
      return tpc.chainType === '10'
        ? // Contract address for Optimism
          OTTERSPACE_BADGE_CONTRACT_ADDRESSES[0]
        : // Contract address for Goerli
          OTTERSPACE_BADGE_CONTRACT_ADDRESSES[1];
    default:
      return tpc.address?.trim();
  }
}

function createAssetUri(tpc: DiscordToken) {
  if (tpc.tokenType === 'Twitter') return 'twitter:twitter/profile:data';
  const networkName = getNetworkNameByTokenType(tpc.tokenType);
  const chainType = overrideChainType(tpc);
  const tokenType = overrideTokenType(tpc);
  const address = overrideAddress(tpc);
  const optionalTokenId = tpc.traitsId
    ? `/${encodeURIComponent(tpc.traitsId)}`
    : '';
  return `${networkName}:${chainType}/${tokenType}:${address}${optionalTokenId}`;
}

function prepareTokenData(tpc: DiscordToken) {
  const isSolana = tpc.tokenType?.startsWith('SOLANA');
  const isStaking = tpc.tokenType?.startsWith('staking');
  const isAxie = tpc.tokenType === 'AXIE';

  let conditionalFields: Partial<TokenPermissionedChat> = {};

  if (tpc.traits.length > 0 && tokenTypesWithMetadata.includes(tpc.tokenType)) {
    const asset = createAssetUri(tpc);

    const traits = tpc.traits.map((data) => ({
      trait_type: isSolana ? data.name?.toLowerCase() : data.name,
      value: isSolana ? data.value?.toLowerCase() : data.value,
    }));

    let stakingAsset = '';
    if (isStaking)
      stakingAsset = tpc.collectionName.includes('-')
        ? tpc.collectionName.split('-')[1].trim()
        : tpc.collectionName;

    conditionalFields = {
      scheme: getSchemeByTokenType(tpc.tokenType),
      asset,
      query: '#ownsTraits',
      requiresMetadata: tpc.traits.length > 0,
      variables: {
        stakingAssetName: stakingAsset,
        _filter: {
          asset,
          condition: 'and',
          filters: [
            {
              condition: tpc?.traitsCondition,
              minCount:
                tpc.minAmount && Number(tpc.minAmount) >= 0
                  ? tpc.minAmount
                  : undefined,
              maxCount:
                tpc.maxAmount && Number(tpc.maxAmount) >= 0
                  ? tpc.maxAmount
                  : undefined,
              traits,
            },
          ],
        },
      },
      version: RuleVersions.V2_1,
    };
  } else if (tpc.tokenType === 'ERC777') {
    const asset = createAssetUri(tpc);
    conditionalFields = {
      scheme: 'superfluid',
      chainId: parseInt(tpc.chainType || '0', 10),
      type: tpc.tokenType,
      asset,
      query: 'superfluid',
      variables: {
        _asset: asset,
        _to: tpc.streamReceiver,
        _minMonthlyRate: tpc.minFlowRate,
      },
      version: RuleVersions.V2_1,
    };
  } else if (tpc.tokenType === 'gnosis') {
    const asset = createAssetUri(tpc);
    conditionalFields = {
      scheme: 'gnosis',
      asset,
      query: '',
      version: RuleVersions.V2_1,
    };
  } else if (tpc.tokenType === 'WILD_FOREST') {
    const asset =
      'wild-forest:ronin/erc721:0xa1ce53b661be73bf9a5edd3f0087484f0e3e7363';
    conditionalFields = {
      chainId: 8000000002020,
      description: tpc.description,
      name: tpc.name,
      roleId: tpc.roleId,
      query: tpc.query,
      minToken: '1',
      requiresMetadata: false,
      type: 'WILD_FOREST',
      asset,
      version: RuleVersions.V2_1,
    };
  } else if (tpc.tokenType === 'otterspace') {
    const asset = createAssetUri(tpc);
    conditionalFields = {
      scheme: getSchemeByTokenType('ERC721'),
      chainId: parseInt(tpc.chainType || '0', 10),
      type: 'ERC721',
      asset,
      query: '#ownsTraits',
      requiresMetadata: true,
      variables: {
        _filter: {
          asset,
          condition: 'and',
          filters: [
            {
              condition: 'and',
              minCount:
                tpc.minAmount && Number(tpc.minAmount) >= 0
                  ? tpc.minAmount
                  : undefined,
              maxCount:
                tpc.maxAmount && Number(tpc.maxAmount) >= 0
                  ? tpc.maxAmount
                  : undefined,
              traits: [
                {
                  trait_type: 'raftTokenId',
                  value: tpc.raftTokenId,
                },
                {
                  trait_type: 'name',
                  value: tpc.otterspaceName,
                },
                {
                  trait_type: 'expiresAt',
                  value: '$ = null or $toMillis($) >= $millis()',
                },
              ],
            },
          ],
        },
      },
      version: RuleVersions.V2_1,
    };
  } else if (isAxie) {
    conditionalFields = {
      name: tpc.name,
      description: tpc.description,
      asset:
        !tpc.asset || tpc.asset === ''
          ? `axie:ronin/erc721:0x32950db2a7164aE833121501C797D79E7B79d74C`
          : tpc.asset,
      chainId: parseInt(tpc.chainType, 10),
      type: overrideTokenType(tpc),
    };
    if (tpc.description) conditionalFields.description = tpc.description;
    if (tpc.query) conditionalFields.query = tpc.query;
    if (tpc.requiresMetadata)
      conditionalFields.requiresMetadata = tpc.requiresMetadata;
    if (tpc.variables) conditionalFields.variables = tpc.variables;
    if (tpc.version) conditionalFields.version = tpc.version;

    const data = {
      roleId: tpc.roleId,
      name: tpc.name,
      groupId: tpc.groupId,
      createdTime: (Date.now() + 1).toString(),
      ...conditionalFields,
    };
    return data;
  } else if (tpc.tokenType === 'FRIEND_TECH') {
    conditionalFields = {
      chainId: 8453,
      contractAddress: tpc.address,
      type: 'friendtech',
      minToken:
        tpc.minAmount && Number(tpc.minAmount) >= 0 ? tpc.minAmount : '0',
      maxToken:
        tpc.maxAmount && Number(tpc.maxAmount) >= 0 ? tpc.maxAmount : undefined,
      version: RuleVersions.V1,
    };
  } else {
    conditionalFields = {
      chainId: parseInt(tpc.chainType || '0', 10),
      type: overrideTokenType(tpc),
      minToken:
        tpc.minAmount && Number(tpc.minAmount) >= 0 ? tpc.minAmount : '0',
      maxToken:
        tpc.maxAmount && Number(tpc.maxAmount) >= 0 ? tpc.maxAmount : undefined,
      version: RuleVersions.V1,
    };
  }

  if (tokenTypesWithTokenId.includes(tpc.tokenType)) {
    let tokenId = tpc.traitsId?.trim();

    if (tokenIdIsUrl(tpc.traitsId?.trim()))
      tokenId = encodeURIComponent(tpc.traitsId?.trim());

    conditionalFields = { ...conditionalFields, tokenId };
  }

  if (tpc.tokenType === 'SOLANA_NFT') {
    conditionalFields = { ...conditionalFields, tokenId: tpc.address?.trim() };
  }

  if (tpc.tokenType === 'POAP') {
    delete conditionalFields.minToken;
    delete conditionalFields.maxToken;
  }

  const data = {
    ...dataByTokenType(tpc),
    roleId: tpc.roleId,
    groupId: tpc.groupId,
    tokenSymbol: tpc.tokenSymbol ?? '',
    name: tpc.name,
    createdTime: (Date.now() + 1).toString(),
    ...conditionalFields,
  };

  return stripEmptyStringAttributes(data, ['query']);
}

export function createTpc(
  communityId: string,
  tpc: DiscordToken,
  callback: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchRequest());

    try {
      const preparedData = prepareTokenData(tpc);
      await getCollabClient().community.createTpc(communityId, preparedData);

      await dispatch(refreshTpcs(communityId));

      await dispatch(
        addToast({
          status: 'success',
          description: 'TGR was created successfully',
        }),
      );

      callback();
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
    }
  };
}

export function updateTpc(
  communityId: string,
  tpcId: string,
  sk: string,
  tpc: DiscordToken,
  callback: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchRequest());
    try {
      const preparedData = prepareTokenData(tpc);

      await getCollabClient().community.replaceTpc(communityId, sk, {
        id: tpcId,
        sk,
        ...preparedData,
      });

      await dispatch(refreshTpcs(communityId));

      await dispatch(
        addToast({
          status: 'success',
          description: 'TGR was edited successfully',
        }),
      );

      callback();
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
    }
  };
}

export function getBackgroundCheckStatus(communityId: string) {
  return httpClient(
    `/communities/${encodeURIComponent(`${communityId}`)}/tpcs/status`,
  );
}

export function updateTpcStatus(
  communityId: string,
  tpcId: string,
  status,
  callback?: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchRequest());
    try {
      await httpClient(
        `/communities/${encodeURIComponent(
          communityId,
        )}/tpcs/${encodeURIComponent(tpcId)}/status`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            status,
          }),
        },
      );
      await dispatch(refreshTpcs(communityId));
      await dispatch(
        addToast({
          status: 'success',
          description: `TGR is ${status}`,
        }),
      );

      if (callback) {
        callback();
      }
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      await dispatch(
        addToast({
          status: 'error',
          description: e.message,
        }),
      );

      dispatch(fetchError(errorMessage));
    }
  };
}

export function updateTpcRole(
  communityPk: string,
  sk: string,
  roleId: string,
  callback?: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(fetchRequest());
      const data = await getCollabClient().community.findTpcs(communityPk);

      // @ts-expect-error
      const { pk, source, ...selectedTpc } = data.items.find(
        (tpc) => tpc.sk === sk,
      );

      await getCollabClient().community.replaceTpc(communityPk, sk, {
        ...selectedTpc,
        roleId,
      });

      await dispatch(refreshTpcs(communityPk));

      if (callback) callback();
    } catch (e) {
      if (e.status === 401) dispatch({ type: TOKEN_EXPIRED });
      else dispatch(fetchError(e.message));
      if (callback) callback(e);
    }
  };
}

export function removeAllInvalidTpcs(
  communityPk: string,
  callback?: (e?: Error) => void,
) {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<void> => {
    try {
      dispatch(fetchRequest());
      const { items } = getState().community.communityGroups;

      const community = items.find((item) => item.pk === communityPk);

      await Promise.all(
        community.invalidTpcs.map((tpc) =>
          getCollabClient().community.deleteTpc(community.pk, tpc.sk),
        ),
      );
      await dispatch(refreshTpcs(communityPk));
      if (callback) callback();
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
      callback();
    }
  };
}

export function createSupportTicket(
  body,
  callback: (e?: Error, ticketId?: string) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const request = await getCollabClient().freshdesk.createTicket(body);
      // eslint-disable-next-line
      callback(null, request.id);
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e);
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
    }
  };
}

export function redditValidateAppCredentials(
  body: {
    targetSubredditName: string;
    targetSubredditId: string;
  },
  callback: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { targetSubredditName, targetSubredditId } = body;
      await getCollabClient().reddit.addCommunityConfig({
        targetSubredditId,
        targetSubredditName,
      });

      const redirectURI = window.location.origin;

      // Redirect user to the authorize endpoint to complete the bot registration
      window.location.href = `${
        process.env.REACT_APP_API_URL
      }/reddit/authorize?${new URLSearchParams({
        flow: 'admin:refresh',
        state: targetSubredditId,
        subreddit: targetSubredditName,
        redirect_uri: redirectURI,
      })}`;
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e);
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
      callback(e);
    }
  };
}

export const toggleRedditConnectModal = () =>
  ({
    type: actionTypes.TOGGLE_REDDIT_CONNECT_MODAL,
  } as const);

const updateWalletInfo = (payload: {
  communityPk: string;
  wallet: CommunityWallet;
}) => ({
  type: actionTypes.UPDATE_WALLET_INFO,
  payload,
});
const createWalletError = (payload: {
  communityPk: string;
  errorMessage: string;
}) => ({
  type: actionTypes.CREATE_WALLET_ERROR,
  payload,
});

export function createWallet(communityPk: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const wallet = await getCollabClient().communityWallet.createWallet(
        communityPk,
      );

      dispatch(updateWalletInfo({ communityPk, wallet }));
      dispatch(
        addToast({
          status: 'success',
          description: 'Creating wallet...',
        }),
      );
    } catch (e) {
      if (e.status === 401) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }
      const errorMessage =
        e?.response?.obj?.error?.message ?? 'Something went wrong.';
      dispatch(createWalletError({ communityPk, errorMessage }));
      dispatch(
        addToast({
          status: 'error',
          description: "Couldn't create wallet",
        }),
      );
    }
  };
}

export function checkWalletStatus(communityPk: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const wallet = await getCollabClient().communityWallet.getWalletDetail(
        communityPk,
      );

      dispatch(updateWalletInfo({ communityPk, wallet }));
    } catch (e) {
      if (e.status === 401) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }
      const errorMessage =
        e?.response?.obj?.error?.message ?? 'Something went wrong.';
      dispatch(createWalletError({ communityPk, errorMessage }));
      dispatch(
        addToast({
          status: 'error',
          description: "Couldn't create wallet",
        }),
      );
    }
  };
}

function prepareRoleCompositionData(data: RoleCompositionFormData) {
  const { action, enforceAction, condition, operator } = data;

  const finalAction = enforceAction
    ? action === 'add'
      ? 'addOrRemove'
      : 'removeOrAdd'
    : action;
  const preparedData = {
    name: data.name,
    roleId: data.roleId,
    query: '',
    asset: 'roles:roles/roles:roles',
    scheme: 'role_composition',
    variables: {
      roleComposition: {
        action: finalAction,
        condition,
        operator,
      },
    },
    createdTime: (Date.now() + 1).toString(),
    version: RuleVersions.V2_1,
  };
  return preparedData;
}

export function createRoleCompositionTpc(
  communityPk: string,
  data: RoleCompositionFormData,
  callback: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchRequest());

    try {
      const preparedData = prepareRoleCompositionData(data);
      await getCollabClient().community.createTpc(communityPk, preparedData);

      await dispatch(refreshTpcs(communityPk));

      await dispatch(
        addToast({
          status: 'success',
          description: 'Rule was created successfully',
        }),
      );

      callback();
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
    }
  };
}

export function updateRoleCompositionTpc(
  communityId: string,
  tpcId: string,
  sk: string,
  data: RoleCompositionFormData,
  callback: (e?: Error) => void,
) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchRequest());

    try {
      const preparedData = prepareRoleCompositionData(data);
      await getCollabClient().community.replaceTpc(communityId, sk, {
        id: tpcId,
        sk,
        ...preparedData,
      });

      await dispatch(refreshTpcs(communityId));

      await dispatch(
        addToast({
          status: 'success',
          description: 'Rule was edited successfully',
        }),
      );

      callback();
    } catch (e) {
      const { isTokenExpired, errorMessage } = handleStatusErrors(e, {
        forwardApiError: false,
      });
      if (isTokenExpired) {
        dispatch({ type: TOKEN_EXPIRED });
        return;
      }

      dispatch(fetchError(errorMessage));
    }
  };
}
